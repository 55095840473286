import React from 'react'
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Failure from '../components/Failure/Failure'
import { Router } from "@reach/router"
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'

import { PageProps } from 'gatsby'

const failure: React.FC<PageProps> = ({ location }) => {
   return (
      <Layout location={location}>
         <SEO title='Fracasso' />
         <Router>
            <PrivateRoute path="/fracasso/" component={Failure} />
         </Router>
      </Layout>
   )
}

export default failure
