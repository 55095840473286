import React from 'react'

type FailureType = {
   
}

const Failure: React.FC<FailureType> = () => {
   return (
      <div>
         fracasso :(
      </div>
   )
}

export default Failure
